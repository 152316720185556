import React, {Component} from 'react';
import {Badge, Card, FormGroup, Input, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Alert} from 'reactstrap';
import { Link } from 'react-router-dom';

import {free} from '../api';
import axios from 'axios';
import { FormattedMessage, injectIntl } from "react-intl";
import LocalizedLink from './LocalizedLink';

export default injectIntl(class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {page: 1, itemPerPage: 25, query: "", sort: "id", loading: true, desc: true, ...(this.props.defaultParams || {})}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps || this.props.url !== prevProps.url || this.props.defaultParams !== prevProps.defaultParams) {
            console.log('Default: ', this.props.defaultParams);
            this.setState({
                params: {
                    page: 1,
                    itemPerPage: 25,
                    query: "",
                    sort: "id",
                    desc: true, ...(this.props.defaultParams || {})
                }
            }, _ => this.componentDidMount());
        }
    }

    async componentDidMount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }

        if (!this.state.loading) {
            this.setState({ loading: true });
        }

        try {
            this.cancelToken = axios.CancelToken.source();
            var data = await free.get(this.props.url, {params: this.state.params, cancelToken: this.cancelToken.token});
            this.setState({data: data.data});
        } catch(e) {
            if (!axios.isCancel(e)) {
                throw e;
            }
        }

        this.setState({ loading: false });
    }

    async updateAndReload(params, e) {
        e.preventDefault();

        if (this.state.data.pageCount !== 0 && (params.page > this.state.data.pageCount || params.page <= 0)) {
            return;
        }

        this.setState({params: {...this.state.params, ...params}}, _ => this.componentDidMount());
    }

    th(th, index) {
        return <th key={index} {...th.props}
                   style={{cursor: 'pointer'}}
                   onClick={this.updateAndReload.bind(this, {
                       sort: th.props.sort,
                       desc: this.state.params.sort === th.props.sort ? !this.state.params.desc : true
                   })}
        >{th.props.children} {this.state.params.sort === th.props.sort ? this.state.params.desc ? <i className="fa fa-sort-down"/> :
            <i className="fa fa-sort-up"/> : ''}
        </th>;
    }
    
    render() {
        if (!this.state.data)
            return <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                <div>
                    <i className="fa fa-spin fa-spinner" style={{fontSize: '30pt', marginBottom: '30px'}} aria-hidden="true" />
                </div>
                <h3><FormattedMessage id="front_wait" /></h3>
            </div>;

        return <div>
            <FormGroup row>
                <Col xs="12" md="2">
                    {this.props.add && <LocalizedLink to={this.props.add} className="col-md-12 btn btn-primary font-xl d-block mb-12"><i className="fa fa-plus" /> <FormattedMessage id="kurs_ausschreiben" /></LocalizedLink>}
                </Col>
                <Col xs="12" md="6"></Col>
                <Col xs="12" md="4">
                    <Input placeholder={this.props.intl.formatMessage({ id: 'general_search' })} type="text" value={this.state.params.query}
                           onChange={e => this.updateAndReload({query: e.currentTarget.value, page: 1}, e)}/>
                </Col>
            </FormGroup>
            { !!this.state.loading && <Alert color="warning"><i className="fa fa-spin fa-spinner" />{' '}<FormattedMessage id="front_wait" /></Alert> }
            <Table className="list-table">
                <thead>
                <tr>
                    {React.Children.map(
                        this.props.children[0].props.children.props.children, (th, index) => th && (
                            th.type === React.Fragment ? React.Children.map(th.props.children, (th, index) => !th ? null : th.props && th.props.sort ? this.th(th, index) : th)
                                : th.props && th.props.sort ? this.th(th, index) : th)
                    )}
                </tr>
                </thead>
                <tbody>
                {this.state.data.data && this.state.data.data.map(this.props.children[1].props.children)}
                </tbody>
            </Table>
            <nav>
                <Pagination listClassName="justify-content-center">
                    <PaginationItem disabled={this.state.data.currentPage === 1}
                                    onClick={this.updateAndReload.bind(this, {page: this.state.data.current_page - 1})}><PaginationLink previous
                                                                                                                                        tag="button"><FormattedMessage id="front_prev" /></PaginationLink></PaginationItem>
                    {([...Array(this.state.data.pageCount)].map((_, sayfa) => (
                        <PaginationItem key={sayfa} active={this.state.data.currentPage === sayfa + 1}>
                            <PaginationLink tag="button" onClick={this.updateAndReload.bind(this, {page: sayfa + 1})}>{sayfa + 1}</PaginationLink>
                        </PaginationItem>)))}
                    <PaginationItem disabled={this.state.data.currentPage === this.state.data.pageCount}
                                    onClick={this.updateAndReload.bind(this, {page: this.state.data.currentPage + 1})}><PaginationLink next
                                                                                                                                        tag="button"><FormattedMessage id="front_next" /></PaginationLink></PaginationItem>
                </Pagination>
            </nav>
        </div>;
    }
});
