
import React, { Component } from 'react';
import { components } from 'react-select';
import { FormattedMessage } from "react-intl";

import ApiSelect from './ApiSelect.jsx';

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
        { props.data.icon && <img width="30" height="20" style={{ marginRight: '5px' }} src={props.data.icon.access_url} alt={props.data.label} /> }
        <FormattedMessage id={ props.data.label } />
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
        {props.data.icon && <img width="30" height="20" style={{ marginRight: '5px' }} src={props.data.icon.access_url} alt={props.data.label} />}
        <FormattedMessage id={ props.data.label } />
    </components.SingleValue>
  );
}

export default function CourseSelect(stuff) {
    return <ApiSelect components={{ SingleValue, Option }} url="/course?sort=code&desc=true" {...stuff} />
}
