
import React, { Component } from 'react';
import { Badge, Card, FormGroup, Input, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import { FormattedMessage } from "react-intl";
import { components } from 'react-select';

import { free } from '../api';
import axios from 'axios';

import AsyncSelect from 'react-select/async';

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
        <FormattedMessage id={ props.data.label } />
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
        <FormattedMessage id={ props.data.label } />
    </components.SingleValue>
  );
}

const MultiValueLabel = props => {
  return <components.MultiValueLabel {...props}>
    <FormattedMessage id={ props.data.label } />
  </components.MultiValueLabel>;
};


export default class ApiSelect extends Component {
  handleChange(param) {
      if(this.props.isMulti) {
        this.props.onChange((param && param.map(param => ({ id: param.value, name: param.label, icon: param.icon }))) || null);
      } else {
        this.props.onChange((param && { id: param.value, name: param.label, icon: param.icon }) || null);
      }
  }

  async loadData(arama) {
    if(this.cancelToken) {
      this.cancelToken.cancel();
    }

    this.cancelToken = axios.CancelToken.source();

    var data = await free.get(this.props.url, { params: { query: arama, itemPerPage: -1 }, cancelToken: this.cancelToken.token });
    return data.data.data
      .map(data => ({ value: data.id, label: data.name, icon: data.icon }));
  }

  render() {
    return <AsyncSelect components={this.props.components || { SingleValue, Option, MultiValueLabel }} styles={{ control: (provided) => ({ borderRadius: 0, ...provided, ...{ borderRadius: 0 } }) }} key={this.props.url} value={this.props.value && (this.props.isMulti ? (this.props.value || []).map(value => ({ value: value.id, label: value.name, icon: value.icon })) : { value: this.props.value.id, label: this.props.value.name, icon: this.props.value.icon })} onChange={this.handleChange.bind(this)} cacheOptions={this.props.url} defaultOptions isClearable={true} loadOptions={this.loadData.bind(this)} isMulti={this.props.isMulti} />;
  }
}
