import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'font-awesome/css/font-awesome.css';

import GroupAdd from './Groups/Add.jsx';
import GroupList from './Groups/List.jsx';
import GroupJoin from './Groups/Join.jsx';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";


import { IntlProvider } from 'react-intl';

import languageDe from './i18n/messages/de.json';
import languageEn from './i18n/messages/en.json';

const languages = {
    de: languageDe,
    en: languageEn
};

const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

function App() {
  return (
    <Router>
      <Route path="/:lang([a-z]{2})">
        {({ match, location }) => {
          var lang = match && match.params && match.params.lang;
          
          var messages = lang && languages[lang];
          if(!messages) {
            return <Redirect to={`/de`} />
          }
          
          return <IntlProvider locale={lang} messages={messages}>
            <Switch>
              <Route exact path={"/" +lang + "/"} component={GroupList} />
              <Route exact path={"/" +lang + "/add"} component={GroupAdd} />
              <Route exact path={"/" +lang + "/group/:id/join"} component={GroupJoin} />
            </Switch>
          </IntlProvider>;
        }}
      </Route>
    </Router>
  );
}

export default App;
