import React, { Component } from 'react';

import { Container, Row, Col, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';

import ApiSelect from '../Common/ApiSelect';
import { free, hatagostervalue } from '../api';
import Swal from 'sweetalert2';
import UserInfo from '../Common/UserInfo';
import CourseSelect from '../Common/CourseSelect';
import Validator from '../Common/Validator';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import LocalizedLink from '../Common/LocalizedLink';

export default injectIntl(class GroupAdd extends React.Component {
    state = {
        user: {

        }
    };

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                default: this.props.intl.formatMessage({ id: 'front_this_field_required' })
            }
        });
    }

    async componentDidMount() {
        var group = await hatagostervalue(free.get('/group/' + this.props.match.params.id));
        this.setState({ group: group.data });
    }

    async handleSend() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }
      

        Swal.showLoading();

        try {
            var data = await hatagostervalue(free.put('/group/' + this.props.match.params.id + '/join', this.state.user));

            this.props.history.push('/' + this.props.intl.locale +'/');
            Swal.fire(
                this.props.intl.formatMessage({ id: 'front_success' }),
                this.props.intl.formatMessage({ id: 'front_register_done' }),
                'success'
            );
        } catch(e) {
            
        }
    }

    render() {
        if(!this.state.group) {
            return null;
        }

        return <Container>
            <Row>
                <Col md="6" className="align-self-center">
                    <div className="box highlight">
                        <table class="table info-table ">
                            <tbody>
                                <tr>
                                    <td colSpan="2">
                                        <LocalizedLink to="/" className="btn btn-primary mb-2"><i className="fa fa-angle-double-left" aria-hidden="true" /> <FormattedMessage id="front_back" /></LocalizedLink>
                                    </td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="front_group_type" /></th>
                                    <td><FormattedMessage id={this.state.group.group_type.name} /></td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="front_course" /></th>
                                    <td><FormattedMessage id={this.state.group.course.name} /></td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="front_level" /></th>
                                    <td><FormattedMessage id={this.state.group.level.name} /></td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="group_course_time" /></th>
                                    <td>{ this.state.group.times.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>) }</td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="front_course_place" /></th>
                                    <td>{ this.state.group.cities.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>) }</td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="front_register" /></th>
                                    <td>{ this.state.group.users.length } / { this.state.group.group_type.max_count }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col md="6" className="align-self-center">
                    <UserInfo
                        value={this.state.user}
                        onChange={a => this.setState({ user: a })}
                        validator={this.validator}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="3"></Col>
                <Col md="6" className="align-self-center text-center">
                    <Validator name="tos" type="required" controller={this}>
                        <CustomInput
                            type="checkbox"
                            id="tos"
                            checked={ this.state.tos }
                            onChange={a => this.setState({ tos: !this.state.tos })}
                            label={<><a href={ this.props.intl.formatMessage({ id:'front_impressum' })} target="_blank"><FormattedMessage id="front_contract" /></a> <FormattedMessage id="front_read_accept" /></>} />
                    </Validator>

                    <div className="text-center">
                        <Button color="primary" className="mt-2" onClick={this.handleSend.bind(this)}><FormattedMessage id="front_send" /></Button>
                    </div>
                </Col>
            </Row>
        </Container>;
    }
});
