
import React, { Component } from 'react';

import { Container, Row, Col, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';

import ApiSelect from '../Common/ApiSelect';
import Validator from '../Common/Validator';
import { FormattedMessage } from 'react-intl';

export default class UserInfo extends React.Component {
    render() {
        return <>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label><FormattedMessage id="front_name" /></Label>
                        <Validator
                            name="first_name"
                            value={this.props.value.first_name}
                            type="required"
                            validator={this.props.validator}>
                            <Input type="text"
                                value={this.props.value.first_name}
                                onChange={a => this.props.onChange( { ...this.props.value, first_name: a.currentTarget.value  } )}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label><FormattedMessage id="front_surname" /></Label>
                        <Validator
                            name="last_name"
                            value={this.props.value.last_name}
                            type="required"
                            validator={this.props.validator}>
                            <Input type="text"
                                value={this.props.value.last_name}
                                onChange={a => this.props.onChange( { ...this.props.value, last_name: a.currentTarget.value  } )}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label><FormattedMessage id="front_adress" /></Label>
                <Validator
                            name="address"
                            value={this.props.value.address}
                            type="required"
                            validator={this.props.validator}>
                    <Input type="text"
                        value={this.props.value.address}
                        onChange={a => this.props.onChange( { ...this.props.value, address: a.currentTarget.value  } )}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label><FormattedMessage id="front_tel" /></Label>
                <Validator
                    name="phone"
                    value={this.props.value.phone}
                    type="required"
                    validator={this.props.validator}>
                    <Input type="text"
                        value={this.props.value.phone}
                        onChange={a => this.props.onChange( { ...this.props.value, phone: a.currentTarget.value  } )}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label><FormattedMessage id="front_mail" /></Label>
                <Validator
                    name="email"
                    value={this.props.value.email}
                    type="required|email"
                    validator={this.props.validator}>
                    <Input type="text"
                        value={this.props.value.email}
                        onChange={a => this.props.onChange( { ...this.props.value, email: a.currentTarget.value  } )}
                    />
                </Validator>
            </FormGroup>
        </>;
    }
}
