import React, {Component} from 'react';
import GenelList from '../Common/GenelList';
import { FormattedMessage } from "react-intl";
import LocalizedLink from '../Common/LocalizedLink';
 
export default class List extends Component {
    state = { mobile: null };

    constructor(props) {
        super(props);
        this.listen = this.listen.bind(this);

        this.mql = window.matchMedia("screen and (max-width: 768px)");
        this.state.mobile = this.mql.matches;
    }

    componentDidMount() {
        this.mql.addListener(this.listen);

    }

    componentWillUnmount() {
        this.mql.removeListener(this.listen);
    }

    listen(e) {
        console.log('test!!');
        if(e.matches){
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    render() {
        if(this.state.mobile === null) {
            return null;
        } 

        if(this.state.mobile) {
            return <GenelList url="/group?status_id=1" islem={false} add="/add">
                <>
                    <th><FormattedMessage id="front_platz" /></th>
                    <th><FormattedMessage id="details" /></th>
                </>
                {
                    row => <>
                        <td>
                            { row.users.map(user => <span className="userbox">{user.first_name} {user.last_name}.</span>) }
                            <br />
                            { row.status.id == 1 && <LocalizedLink to={"/group/" + row.id + "/join"} className="joinbox">
                                <i className="fa fa-hand-pointer-o" /> <FormattedMessage id="front_join" />
                            </LocalizedLink> }
                        </td>
                        <td className="text-center">
                            <div><b><FormattedMessage id="front_group_type" />:</b> <FormattedMessage id={row.group_type.name} /></div>
                            <div><b><FormattedMessage id="front_course" />:</b> <FormattedMessage id={row.course.name} /></div>
                            <div><b><FormattedMessage id="front_course_time" />:</b> {row.times.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>)}</div>
                            <div><b><FormattedMessage id="front_course_place" /></b> {row.cities.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>)}</div>
                        </td>
                    </>
                }
            </GenelList>;
        }

        return <GenelList url="/group?status_id=1" islem={false} add="/add">
            <>
                <th><FormattedMessage id="front_platz" /></th>
                <th><FormattedMessage id="front_group_type" /></th>
                <th><FormattedMessage id="front_course" /></th>
                <th><FormattedMessage id="front_level" /></th>
                <th><FormattedMessage id="front_course_time" /></th>
                <th><FormattedMessage id="front_course_place" /></th>
            </>
            {
                row => <>
                    <td>
                        { row.users.map(user => <span className="userbox">{user.first_name} {user.last_name}.</span>) }
                        { row.status.id == 1 && <LocalizedLink to={"/group/" + row.id + "/join"} className="joinbox">
                            <i className="fa fa-hand-pointer-o" /> <FormattedMessage id="front_join" />
                        </LocalizedLink> }
                    </td>
                    <td className="text-center"><FormattedMessage id={row.group_type.name} /></td>
                    <td className="text-center">
                        { row.course.icon && <img width="30" height="20" style={{ display: 'block', margin: '5px auto' }} src={row.course.icon.access_url} alt={row.course.name} /> }
                        <FormattedMessage id={row.course.name} />
                    </td>
                    <td className="text-center"><b><FormattedMessage id={row.level.name} /></b></td>
                    <td>{row.times.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>)}</td>
                    <td>{row.cities.map(a => <span key={a.id} className="seperate"><FormattedMessage id={a.name} /></span>)}</td>
                </>
            }
        </GenelList>;
    }
}
