import React, {Component} from 'react';
import DataTable from './DataTable';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import Moment from 'react-moment';

export default class GenelList extends Component {
    constructor(props) {
        super(props);

        this.dataTable = React.createRef();
    }

    async handleEdit(parameter, e) {
        e.preventDefault();

        this.props.history.push(this.props.edit(parameter.id));
    }

    render() {
        return <>
            <DataTable add={this.props.add} url={this.props.url} ref={this.dataTable} defaultParams={this.props.defaultParams}>
                <thead>
                <tr>
                    <th sort="id"><FormattedMessage
                        id="online_seit"/></th>
                    {this.props.children[0]}
                    {(this.props.islem !== false && <th><FormattedMessage id="front_operations"/></th>) || null}
                </tr>
                </thead>
                <tbody>
                {
                    row => <tr key={row.id}>
                        <td className="text-center"><b><Moment format="DD.MM.YYYY" date={new Date(row.created_at)}/></b></td>
                        {this.props.children[1](row)}
                        {(this.props.islem !== false && <td className="thstyle">
                            {this.props.edit &&
                            <Link to={this.props.edit(row.id)} className="btn btn-sm btn-primary"><i className="fas fa-edit"/> <FormattedMessage
                                id="front_edit"/></Link>}
                        </td>) || null}
                    </tr>
                }
                </tbody>
            </DataTable>
        </>;
    }
}

