import { injectIntl } from "react-intl";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default injectIntl(class extends React.Component {
    render() {
        var props = { ...this.props };
        props.to = '/' + this.props.intl.locale + props.to;

        return <Link {...props} />;
    }
});
