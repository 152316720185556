import React, { Component } from 'react';

import { Container, Row, Col, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';

import ApiSelect from '../Common/ApiSelect';
import { free, hatagostervalue } from '../api';
import Swal from 'sweetalert2';
import UserInfo from '../Common/UserInfo';
import CourseSelect from '../Common/CourseSelect';
import Validator from '../Common/Validator';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import LocalizedLink from '../Common/LocalizedLink';

export default injectIntl(class GroupAdd extends React.Component {
    state = {
        users: [
            {}
        ]
    };

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                default: this.props.intl.formatMessage({ id: 'front_this_field_required' })
            }
        });
    }

    async handleSend() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }
      

        Swal.showLoading();

        try {
            var data = await hatagostervalue(free.put('/group', this.state));

            this.props.history.push('/' + this.props.intl.locale +'/');
            
            Swal.fire(
                this.props.intl.formatMessage({ id: 'front_success' }),
                this.props.intl.formatMessage({ id: 'front_register_done' }),
                'success'
            );
        } catch(e) {
            
        }
    }

    render() {
        return <Container>
            <LocalizedLink to="/" className="btn btn-primary mb-2"><i className="fa fa-angle-double-left" aria-hidden="true" /> <FormattedMessage id="front_back" /></LocalizedLink>
            <Row>
                <Col md="6">
                    <UserInfo
                        value={this.state.users[0]}
                        onChange={a => this.setState({ users: [a] })}
                        validator={this.validator}
                    />
                    <FormGroup>
                        <Label><FormattedMessage id="front_note" /></Label>
                        <Validator name="note" type="required" controller={this}>
                            <Input type="textarea" rows="4"
                                value={this.state.note}
                                style={{ minHeight: '121px' }}
                                onChange={a => this.setState({ note: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label><FormattedMessage id="front_course_place" /></Label>
                        <Validator name="cities" type="required" controller={this}>
                            <ApiSelect
                                isMulti
                                url="/city"
                                value={this.state.cities}
                                onChange={a => this.setState({ cities: a })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="front_course_select" /></Label>
                        <Validator name="course" type="required" controller={this}>
                            <CourseSelect
                                value={this.state.course}
                                onChange={a => this.setState({ course: a, level: null })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="front_level_select" /></Label>
                        <Validator name="level" type="required" controller={this}>
                            { this.state.course ? <ApiSelect
                                url={"/course/" + this.state.course.id + "/level"}
                                value={this.state.level}
                                onChange={a => this.setState({ level: a })}
                            /> : <Input type="text" disabled />}
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="front_zamani" /></Label>
                        <Validator name="times" type="required" controller={this}>
                            <ApiSelect
                                isMulti
                                url={"/time"}
                                value={this.state.times}
                                onChange={a => this.setState({ times: a })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="front_course_aim" /></Label>
                        <Validator name="purpose" type="required" controller={this}>
                            <ApiSelect
                                url="/purpose"
                                value={this.state.purpose}
                                onChange={a => this.setState({ purpose: a })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="front_group_type" /></Label>
                        <Validator name="group_type" type="required" controller={this}>
                            <ApiSelect
                                url="/group_type"
                                value={this.state.group_type}
                                onChange={a => this.setState({ group_type: a })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="3"></Col>
                <Col md="6" className="align-self-center text-center">
                    <Validator name="tos" type="required" controller={this}>
                        <CustomInput
                            type="checkbox"
                            id="tos"
                            checked={ this.state.tos }
                            onChange={a => this.setState({ tos: !this.state.tos })}
                            label={<><a href={ this.props.intl.formatMessage({ id:'front_impressum' })} target="_blank"><FormattedMessage id="front_contract" /></a> <FormattedMessage id="front_read_accept" /></>} />
                    </Validator>

                    <div className="text-center">
                        <Button color="primary" className="mt-2" onClick={this.handleSend.bind(this)}><FormattedMessage id="front_send" /></Button>
                    </div>
                </Col>
            </Row>
        </Container>;
    }
});
